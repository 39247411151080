// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCupiNOATmKDQYHWVZTQQ7SPqAYagscIKE",
    authDomain: "mailattach.co",
    projectId: "mailattach-d45f4",
    storageBucket: "mailattach-d45f4.appspot.com",
    messagingSenderId: "240364997082",
    appId: "1:240364997082:web:a4b888d83ed79cc2a7d8e3",
    measurementId: "G-Q7DZSRKLGZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export { auth, googleProvider };
