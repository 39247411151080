import React from 'react';
import { Helmet } from 'react-helmet';
import './LandingPage.css'; // Import the CSS file for styling
import exampleImage from '../assets/mailattach-main-image.png'; // Replace with your actual image path
import gmailIcon from '../assets/gmail-icon.png'; // Import the Gmail icon image

const LandingPage = () => {
    return (
        <div className="landing-page">
            <Helmet>
                <title>MailAttach - Detects missing attachments in Gmail</title>
                <meta
                    name="description"
                    content="The Chrome Extension which detects missing attachments and blocks the email from sending."
                />
            </Helmet>
            <div className="content">
                <div className="text-section">
                    <h1>Detects missing attachments in Gmail</h1>
                    <p style={{color: "grey", fontSize: 20}}>MailAttach is a chrome extension which detects missing attachments and prevents the email from being sent without them. {<br/>}{<br/>}Works in all languages.</p>
                    <a
                        href="https://chromewebstore.google.com/detail/mailattach-ai-powered-mis/oncbebpmgkclejkfcolooflnniokmmka" // Replace with your actual Chrome Web Store URL
                        className="download-button"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={gmailIcon} alt="Gmail Icon" className="gmail-icon" /> Add to Gmail in Chrome now
                    </a>
                    {/*<span>Only on web. Not available on mobile at the moment.</span>*/}
                </div>
                <div className="image-section">
                    <img src={exampleImage} alt="MailAttach Preview" className="preview-image" />
                </div>
            </div>
        </div>
    );
};

export default LandingPage;
